<template>
  <div class="container-fluid py-3">
    <vue-progress-bar></vue-progress-bar>
    <div class="row">
      <div class="col-12">
        <div class="card" v-if="$store.getters['auth/user'].roles.includes('ROLE_AS_REPORT_NUTRITIONAL_VALUES') && $store.getters['auth/user'].roles.includes('ROLE_AS_REPORT_SHOW_LIST')">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Początek okresu <span class="text-danger">*</span></label>
                  <datetime @select="resetData" v-model="dateFrom" type="date" format="yyyy-MM-dd" value-zone="local" zone="local"></datetime>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Koniec okresu <span class="text-danger">*</span></label>
                  <datetime @select="resetData" v-model="dateTo" type="date" format="yyyy-MM-dd" value-zone="local" zone="local"></datetime>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <label for="selectedMeals">Posiłki <span class="text-danger">*</span></label>
                <multiselect @select="resetData" v-model.trim="selectedMeals" :options="mealsList" :custom-label="mealLabel" :multiple="true" :allow-empty="false" :close-on-select="false" :clear-on-select="false" :preserve-search="true" track-by="name" name="selectedMeals" placeholder="Wybierz posiłek" select-label="Wybierz" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" value required>
                  <template slot="singleLabel" slot-scope="props">
                    <span class="option__desc">
                      <span class="option__title">{{ props.option.name }}</span>
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title">{{ props.option.name }}</span>
                    </div>
                  </template>
                  <template slot="selection" slot-scope="{ values, isOpen }">
                    <span v-if="values.length && !isOpen" class="multiselect__single">Wybranych posiłków: {{ values.length }}</span>
                  </template>
                </multiselect>
              </div>
              <div class="col-md-4">
                <label for="selectedDiets">Diety <span class="text-danger">*</span></label>
                <multiselect @select="resetData" v-model.trim="selectedDiets" :options="dietsList" :custom-label="mealLabel" :multiple="true" :allow-empty="false" :close-on-select="false" :clear-on-select="false" :preserve-search="true" track-by="name" name="selectedDiets" placeholder="Wybierz posiłek" select-label="Wybierz" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" value required>
                  <template slot="singleLabel" slot-scope="props">
                    <span class="option__desc">
                      <span class="option__title">{{ props.option.name }}</span>
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title">{{ props.option.name }}</span>
                    </div>
                  </template>
                  <template slot="selection" slot-scope="{ values, isOpen }">
                    <span v-if="values.length && !isOpen" class="multiselect__single">Wybranych diet: {{ values.length }}</span>
                  </template>
                </multiselect>
              </div>
              <div class="col-md-4">
                <label for="selectedNutritional">Wartości odżywcze <span class="text-danger">*</span></label>
                <multiselect @select="resetData" v-model.trim="selectedNutritional" :options="nutritionalList" :custom-label="mealLabel" :multiple="true" :allow-empty="false" :close-on-select="false" :clear-on-select="false" :preserve-search="true" track-by="name" name="selectedNutritional" placeholder="Wybierz posiłek" select-label="Wybierz" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" value required>
                  <template slot="singleLabel" slot-scope="props">
                    <span class="option__desc">
                      <span class="option__title">{{ props.option.name }}</span>
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title">{{ props.option.name }}</span>
                    </div>
                  </template>
                  <template slot="selection" slot-scope="{ values, isOpen }">
                    <span v-if="values.length && !isOpen" class="multiselect__single">Wybranych wartości: {{ values.length }}</span>
                  </template>
                </multiselect>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 align-self-end">
                <button class="btn btn-primary btn-sm" @click="generateReport()" :disabled="disabledBtn">Wyświetl raport</button>
              </div>
              <div class="col-md-6 align-self-end text-right">
                <button class="btn btn-success btn-sm" @click="downloadReport()" :disabled="disabledBtn">Pobierz XLS</button>
              </div>
            </div>
          </div>
        </div>
        <div class="card p-0 m-0 border-0" v-else>
          <div class="alert alert-warning alert--grouped">
            <i class="fe fe-alert-triangle mr-2"></i>
            Uwaga!<br />Zasób nie istnieje lub nie masz do niego dostępu.
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="table-responsive" v-if="reportData && !processing" :set="dietRow = 0">
          <table class="table table-sm table-striped table-bordered" :set="positionSum = []">
            <thead class="font-quick-medium">
              <tr>
                <th>Lp</th>
                <th>Dieta</th>
                <th>Posiłek</th>
                <template v-for="(nutritional, index) in selectedNutritional">
                  <th :key="index">
                    {{ nutritional.name }}
                  </th>
                </template>
              </tr>
            </thead>
            <tbody :set="iterationCounter = 1">
              <template v-for="(dieta) in reportData[1]">
                <template v-for="(meal, index2, iy) in dieta">
                  <template v-for="(it, index3, ii) in meal.meals">
                    <tr :key="'l1'+index2+index3">
                      <td>{{ iterationCounter++ }}</td>
                      <template v-if="ii == 0">
                        <td class="align-middle" :rowspan="Object.keys(meal.meals).length">{{ meal.diet.name }}</td>
                      </template>
                      <td :key="it.uuid">{{it.meal.name}}</td>
                      <template v-for="(su, index4, ix) in it.nutritionalValues.summary">
                        <td :key="'l2'+index2+index3+index4">{{ displayVal(su, reportData[1].countDays) }}
                        <template v-if="parseFloat(dietRow) != parseFloat(iy)">
                          <span class="d-none">{{ dietRow = parseFloat(iy) }} {{ positionSum = [] }}</span>
                        </template>
                        <template v-if="positionSum[ix]">
                          <span class="d-none">{{ positionSum[ix] = parseFloat(positionSum[ix]) + parseFloat((su / reportData[1].countDays).toFixed(1)) }}</span>
                        </template>
                        <template v-else>
                          <span class="d-none">{{ positionSum[ix] = parseFloat((su / reportData[1].countDays).toFixed(1)) }}</span>
                        </template>
                        </td>
                      </template>
                    </tr>
                  </template>
                  <tr :key="'l3'+index2" v-if="meal.diet">
                    <td colspan="3" class="text-right bg-secondary font-weight-bold">Suma:</td>
                    <template v-for="(sume, index) in positionSum">
                      <td :key="index" class="bg-secondary font-weight-bold">{{ sume.toFixed(1) }}</td>
                    </template>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>
        <div v-if="processing" class="loader my-3"></div>
      </div>
    </div>
  </div><!-- /container-fluid -->
</template>

<script>
import { axios_instance as axios } from "@/axios-config.js";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import moment from "moment";
import Multiselect from "vue-multiselect";
export default {
  components: {
    datetime: Datetime,
    Multiselect,
  },
  data() {
    return {
      dateFrom: null,
      dateTo: null,
      mealsList: [],
      dietsList: [],
      selectedMeals: null,
      selectedDiets: null,
      processing: false,
      iterationNumber: 0,
      ctn: 0,
      totalSum: [],
      nutritionalList: [
        { name:'Energia [kcal]', value: 'energyInKcal'},
        { name:'Energia [KJ]', value: 'energyInKj'},
        { name:'Białko [g]', value: 'protein'},
        { name:'Tłuszcz [g]', value: 'fat'},
        { name:'Tłuszcze nasycone [g]', value: 'saturatedFattyAcidsG'},
        { name:'Sól [g]', value: 'saltG'},
        { name:'Węglowodoany [g]', value: 'carbohydrates'},
        { name:'Cukry [g]', value: 'sugarG'},
        { name:'Błonnik pokarmowy [g]', value: 'dietaryFiberG'},
      ],
      // selectedNutritional: null,
      selectedNutritional:  null,
      reportData: null,
    };
  },
  created() {
    this.getMeals();
    this.getDiets();
  },
  methods: {
    resetData() {
      this.totalSum = [];
      this.iterationNumber = 0;
      this.reportData = null;
    },
    displayVal(a,s) {
      let _tempVal = (a / s).toFixed(1);
      return _tempVal;
    },
    mealLabel({ name }) {
      return `${name} `;
    },
    hasNotEmptyColsLabel({ name }) {
      return `${name} `;
    },
    async getMeals() {
      const resource = "app/d_meals?context=dMeal";
      try {
        this.$Progress.start();
        const { data } = await axios.get(resource);
        this.mealsList = data["hydra:member"];
        this.$Progress.finish();
      } catch (error) {
        console.log(error);
        this.$Progress.fail();
      }
    },
    async getDiets() {
      const resource = "app/user_active_diets?context=dietGrid";
      try {
        this.$Progress.start();
        const { data } = await axios.get(resource);
        this.dietsList = data["hydra:member"];
        this.$Progress.finish();
      } catch (error) {
        console.log(error);
        this.$Progress.fail();
      }
    },
    async generateReport() {
      this.reportData = null;
      this.iterationNumber = 0;
      this.totalSum = [];
      this.processing = true;
      this.$Progress.start();

      let startDate = moment(this.dateFrom).format("YYYY-MM-DD");
      let endDate = moment(this.dateTo).format("YYYY-MM-DD");
      let mealGroup = [];
      let nutritionalGroup = [];
      let dietGroup = [];

      this.selectedMeals.forEach((item) => {
        mealGroup.push(item["uuid"]);
      });

      this.selectedNutritional.forEach((item) => {
        nutritionalGroup.push(item["value"]);
      });

      this.selectedDiets.forEach((item) => {
        dietGroup.push(item["uuid"]);
      });

      let jsonToSend = {
        dateFrom: startDate,
        dateTo: endDate,
        mealGroup: mealGroup,
        nutritionalGroup: nutritionalGroup,
        dietGroup: dietGroup,
      };

      const resource = "app/report/nutritional-values?context=reportNutritionalValues";

      try {
        this.$Progress.start();
        const { data } = await axios.post(resource, jsonToSend, {});
        this.reportData = data["hydra:member"];
        this.$Progress.finish();
        this.processing = false;
      } catch (error) {
        console.log(error);
        this.$Progress.fail();
        this.processing = false;
      }
    },
    downloadReport() {
      this.processing = true;
      this.$Progress.start();

      let startDate = moment(this.dateFrom).format("YYYY-MM-DD");
      let endDate = moment(this.dateTo).format("YYYY-MM-DD");
      let mealGroup = [];
      let nutritionalGroup = [];
      let dietGroup = [];

      this.selectedMeals.forEach((item) => {
        mealGroup.push(item["uuid"]);
      });

      this.selectedNutritional.forEach((item) => {
        nutritionalGroup.push(item["value"]);
      });

      this.selectedDiets.forEach((item) => {
        dietGroup.push(item["uuid"]);
      });

      let jsonToSend = {
        dateFrom: startDate,
        dateTo: endDate,
        mealGroup: mealGroup,
        nutritionalGroup: nutritionalGroup,
        dietGroup: dietGroup,
        type: "xls",
      };

      axios
        .post(
          `app/report/nutritional-values?context=reportNutritionalValues`,
          jsonToSend,
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          return response;
        })
        .then((blob) => {
          blob = new Blob([blob.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "RNV_" + startDate + "_" + endDate;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.processing = false;
          this.$Progress.finish();
        });
    },
  },
  computed: {
    disabledBtn() {
      if (
        this.dateFrom &&
        this.dateTo &&
        this.selectedDiets &&
        this.selectedMeals &&
        this.selectedNutritional
      ) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/variables";
@import "../../assets/css/scoped-forms";
</style>
